import { template as template_277c6b7fecce423c9413cb845e244a7c } from "@ember/template-compiler";
const SidebarSectionMessage = template_277c6b7fecce423c9413cb845e244a7c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
