import { template as template_37a880c704a1415fac272e148dc6d7c3 } from "@ember/template-compiler";
const FKControlMenuContainer = template_37a880c704a1415fac272e148dc6d7c3(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
