import { template as template_79f471c852b14cd7a7f2c8e74d17fa52 } from "@ember/template-compiler";
const FKLabel = template_79f471c852b14cd7a7f2c8e74d17fa52(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
