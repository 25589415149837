import { template as template_ac0806a1b7e24ba5b1e4f8ddaca53767 } from "@ember/template-compiler";
const FKText = template_ac0806a1b7e24ba5b1e4f8ddaca53767(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
